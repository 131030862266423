import Image from 'next/image';
import {useRouter} from 'next/router';

import HeaderLink from './HeaderLink';
import {useRequiredUser} from '../../context/UserContext';
import cx from 'classnames';

export default function DesktopMenu() {
  const {pathname} = useRouter();
  const {user} = useRequiredUser();

  return (
    <div className="flex items-baseline gap-x-10">
      <HeaderLink path="/" active={pathname === '/'}>
        Home
      </HeaderLink>

      <HeaderLink
        path={`/curriculum/${user.curriculum.id}`}
        active={pathname.includes('/curriculum')}
      >
        Corsi
      </HeaderLink>

      <HeaderLink
        path="/learning-area"
        active={pathname.includes('/learning-area')}
      >
        Learning Area
      </HeaderLink>

      {/* <div className="group relative">
        <span
          className={cx('text-sm inline-flex gap-2', {
            'border-b':
              pathname.includes('/mdcodes') || pathname.includes('/formula'),
          })}
        >
          Strumenti{' '}
          <Image
            src="/assets/icons/arrow.svg"
            width={12}
            height={12}
            alt="Arrow icon"
          />
        </span>

        <div className="hidden group-hover:flex absolute top-[100%] left-0 p-4 flex-col gap-y-2 bg-white rounded-[10px] z-[1]">
          <span className="block whitespace-nowrap">
            <HeaderLink path="/mdcodes" active={pathname.includes('/mdcodes')}>
              MD Codes™
            </HeaderLink>
          </span>
          <span className="block whitespace-nowrap">
            <HeaderLink path="/formula" active={pathname.includes('/formula')}>
              MD Codes™ Formula
            </HeaderLink>
          </span>
        </div>
      </div> */}

      <HeaderLink path="/chi-siamo" active={pathname === '/chi-siamo'}>
        Chi Siamo
      </HeaderLink>

      <HeaderLink path="/profile" active={pathname === '/profile'}>
        <div>
          <Image
            src="/assets/icons/user.svg"
            width={14}
            height={14}
            alt="Icona profilo"
            className="inline mr-2 mb-1"
          />
          Profilo
        </div>
      </HeaderLink>
    </div>
  );
}
